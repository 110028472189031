<template>
  <div class="home pb-16">
    <div v-for="(item, index) in features" :key="index">
      <div v-if="(index + 1) % 2 === 0" class="d-flex mb-2 rounded">
        <div class="w-50">
          <v-img :src="item.url" :height="rowHeight"></v-img>
        </div>
        <div
          class="w-50 d-flex align-center justify-center blue white--text font-weight-bold text-h4 text-md-h1"
        >
          {{ item.text }}
        </div>
      </div>
      <div v-else class="d-flex mb-2 rounded">
        <div
          class="w-50 d-flex align-center justify-center teal white--text font-weight-bold text-h4 text-md-h1"
        >
          {{ item.text }}
        </div>
        <div class="w-50">
          <v-img :src="item.url" :height="rowHeight"></v-img>
        </div>
      </div>
    </div>
    <div class="d-flex justify-center align-center generate mt-10">
      <v-btn x-large color="primary" @click="$router.push('/generator')">
        开始制作
      </v-btn>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      features: [
        {
          text: "节省大量时间",
          url: require("@/assets/features/quicker.jpg"),
        },
        {
          text: "不重样食材清单",
          url: require("@/assets/features/no-repeat-food.jpg"),
        },
        {
          text: "健康的食材比例",
          url: require("@/assets/features/healthy.jpg"),
        },
      ],
      rowHeight: "500",
    };
  },
  mounted() {
    const { xs: isXSSize, sm: isSMSize } = this.$vuetify.breakpoint;
    if (isSMSize) {
      this.rowHeight = "300";
    }
    if (isXSSize) {
      this.rowHeight = "200";
    }
  },
};
</script>

<style scoped>
.feature-text {
  background-color: #e3f2fd;
}
.row {
  height: 500px;
}
.w-50 {
  width: 50%;
}
</style>
