<template>
  <!-- App.vue -->

  <v-app>
    <v-app-bar elevation="4" dense>
      <router-link class="pr-2 nav-link" to="/">首页</router-link>
      <router-link class="nav-link text-capitalize" to="/generator"
        >清单产生器</router-link
      >
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main class="main-height">
      <!-- Provides the application the proper gutter -->
      <!-- If using vue-router -->
      <div>
        <router-view></router-view>
      </div>
    </v-main>
  </v-app>
</template>
<script>
export default {
  mounted() {},
};
</script>
<style lang="scss">
.nav-link {
  text-decoration: none;
}
// avoid too big navigation area
.main-height {
  min-height: 600px;
}
.v-messages__message {
  line-height: 16px !important;
}
</style>
